import './src/assets/scss/all.scss';

import $ from 'jquery';

export const onInitialClientRender = () => {
	$(document).ready(function (e) {
		$("[class*='mlg_bg-gradient-'][class*='mlg_has-seperator-']").prepend('<div class="mlg_bg-element"></div>');
	});

	// Performs a smooth page scroll to an anchor on the same page
	$(document).on('click', 'a[href*="#"]:not([href="#"])', function () {
		var target = $(this.hash);

		if (target.length) {
			var offset = target.offset().top;

			offset -= parseInt($('.mlg_site-header').outerHeight()) || 0;

			$('html,body').stop().animate(
				{
					scrollTop: offset,
				},
				800
			);
			return false;
		}
	});
};
