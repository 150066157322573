// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-datenschutzerklarung-js": () => import("./../../../src/pages/datenschutzerklarung.js" /* webpackChunkName: "component---src-pages-datenschutzerklarung-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kommune-js": () => import("./../../../src/pages/kommune.js" /* webpackChunkName: "component---src-pages-kommune-js" */),
  "component---src-pages-uber-uns-js": () => import("./../../../src/pages/uber-uns.js" /* webpackChunkName: "component---src-pages-uber-uns-js" */),
  "component---src-pages-unternehmen-js": () => import("./../../../src/pages/unternehmen.js" /* webpackChunkName: "component---src-pages-unternehmen-js" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/verein.js" /* webpackChunkName: "component---src-pages-verein-js" */)
}

